export const isEmpty = (value: any): boolean => {
    if (value === null) {
        return true
    }
    if (typeof value !== 'number' && value === '') {
        return true
    }
    if (typeof value === 'undefined' || value === undefined) {
        return true
    }
    if (
        value !== null &&
        typeof value === 'object' &&
        !Object.keys(value).length
    ) {
        return true
    }
    return false
}

export function sleep(ms: any): Promise<void> {
    return new Promise((resolveFunc) => {
        setTimeout(() => {
            resolveFunc()
        }, ms)
    })
}

export function checkAtob(str: string): boolean {
    try {
        return JSON.parse(atob(str))
    } catch (err) {
        return false
    }
}
