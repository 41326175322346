import { FlexGrid } from '@algolia/satellite'
import type { RouteObject } from 'react-router-dom'

import { useAuth } from '../components/AuthContext'
import { Integrations } from '../components/common/integrations/integrations'
import { Runs } from '../components/common/observability/runs'
import { Sources } from '../components/common/sources/sources'

import { commonCrumbs } from './commonCrumbs'
import { RequireAuth } from './requireAuth'

export const commonRoutes = (): RouteObject[] => {
    const { platform } = useAuth()

    const routes: RouteObject[] = [
        {
            path: '/integrations',
            element: (
                <RequireAuth>
                    <Integrations />
                </RequireAuth>
            ),
            handle: {
                crumb: () => commonCrumbs.integrations,
            },
        },

        {
            path: '/integrations/:integrationId/sources',
            element: (
                <RequireAuth>
                    <Sources />
                </RequireAuth>
            ),

            handle: {
                crumb: (data: Record<string, unknown>) => (
                    <FlexGrid alignment="center">
                        {platform === null && commonCrumbs.integrations}
                        {platform === null && commonCrumbs.separator}
                        {commonCrumbs.sources(data)}
                    </FlexGrid>
                ),
            },
        },

        {
            path: '/integrations/:integrationId/sources/:sourceId/manage/:taskId',
            element: (
                <RequireAuth>
                    <Runs />
                </RequireAuth>
            ),
            handle: {
                crumb: (data: Record<string, unknown>) => (
                    <FlexGrid alignment="center">
                        {platform === null && commonCrumbs.integrations}
                        {platform === null && commonCrumbs.separator}
                        {platform === null && commonCrumbs.sources(data)}
                        {commonCrumbs.separator}
                        {commonCrumbs.runs(data)}
                    </FlexGrid>
                ),
            },
        },

        {
            path: '/integrations/:integrationId/sources/:id',
            element: (
                <RequireAuth>
                    <Sources />
                </RequireAuth>
            ),
            handle: {
                crumb: (data: Record<string, unknown>) => (
                    <FlexGrid alignment="center">
                        {platform === null && commonCrumbs.integrations}
                        {platform === null && commonCrumbs.separator}
                        {commonCrumbs.sources(data)}
                        {commonCrumbs.separator}
                        {commonCrumbs.source(data)}
                    </FlexGrid>
                ),
            },
        },
    ]

    return routes
}
