import { DateRangePickerActionTypes, Button, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { Calendar, RotateCcw } from 'react-feather'

import type { RightSidePanelComponentArgs } from './dateRange'

type Props = {
    panelArgs: RightSidePanelComponentArgs
}

export const DateRangePanel: FunctionComponent<Props> = (props): any => {
    const { panelArgs } = props
    const now = new Date()

    function createStartDate(numDays: number): Date {
        const date = new Date()
        const startDate = new Date(
            date.getTime() - numDays * 24 * 60 * 60 * 1000
        )
        startDate.setHours(0, 0, 0, 0)
        return startDate
    }

    return (
        <div className={stl`flex flex-col items-start space-y-2`}>
            <Button
                variant="subtle"
                startIcon={Calendar}
                className={stl`inline-flex`}
                onClick={(): void => {
                    panelArgs.dispatch({
                        type: DateRangePickerActionTypes.rangeSelect,
                        payload: {
                            start: createStartDate(0),
                            end: now,
                        },
                    })
                }}
            >
                Today
            </Button>
            <Button
                variant="subtle"
                startIcon={RotateCcw}
                className={stl`inline-flex`}
                onClick={(): void => {
                    panelArgs.dispatch({
                        type: DateRangePickerActionTypes.rangeSelect,
                        payload: {
                            start: createStartDate(7),
                            end: now,
                        },
                    })
                }}
            >
                Last week
            </Button>
            <Button
                variant="subtle"
                startIcon={RotateCcw}
                className={stl`inline-flex`}
                onClick={(): void => {
                    panelArgs.dispatch({
                        type: DateRangePickerActionTypes.rangeSelect,
                        payload: {
                            start: createStartDate(14),
                            end: now,
                        },
                    })
                }}
            >
                Last 2 weeks
            </Button>
            <Button
                variant="subtle"
                startIcon={RotateCcw}
                className={stl`inline-flex`}
                onClick={(): void => {
                    panelArgs.dispatch({
                        type: DateRangePickerActionTypes.rangeSelect,
                        payload: {
                            start: createStartDate(30),
                            end: now,
                        },
                    })
                }}
            >
                Last 30 days
            </Button>
        </div>
    )
}
