import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    Field,
    FlexGrid,
    Input,
    Link,
    stl,
    Toggle,
} from '@algolia/satellite'
import { isEmpty } from 'lodash'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { Edit } from 'react-feather'

import AutocompleteService from '../../../services/theming/autocompleteService'
import type { BcChannel } from '../../../types/bigCommerce/channelsRespone.type'
import type {
    AutoComplete,
    AutoCompleteConfig,
} from '../../../types/database/autoComplete.type'
import type { IntegrationType } from '../../../types/database/integration.type'
import type { AutocompleteDefaultCode } from '../../../types/theming/autocompleteDefaultCode.type'
import type { Versioning } from '../../../types/theming/versioning.type'
import { useAlert } from '../../AlertContext'
import { EditCodeModal } from '../../common/editCodeModal'
import { IsDistinctCheckbox } from '../../common/isDistinctCheckbox'
import { RegionsDropdown } from '../../common/regionsDropdown'
import { ShowOutOfStockCheckbox } from '../../common/showOutOfStockCheckbox'
import { SourceDropdown } from '../../common/sourceDropdown'
import { CodeUpdateAvailableBadge } from '../../common/theming/codeUpdateAvailableBadge'

import { ApplyToThemeConfirm } from './applyToThemeConfirm'

type Props = {
    currentChannel: BcChannel | undefined
    currentIntegration: IntegrationType | null | undefined
    currentAutocomplete: AutoComplete | undefined
    setCurrentAutocomplete: (autocomplete: AutoComplete) => void
    isFromAdminView: () => boolean
    setCurrentChannel: (chn: BcChannel | undefined) => void
}

export const AutocompleteConfig: FunctionComponent<Props> = (
    props: Props
): any => {
    const {
        currentIntegration,
        currentChannel,
        currentAutocomplete,
        setCurrentAutocomplete,
        isFromAdminView,
        setCurrentChannel,
    } = props

    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [defaultCode, setDefaultCode] = useState<
        AutocompleteDefaultCode | undefined
    >()
    const [versioning, setVersioning] = useState<Versioning | undefined>()
    const [newAutocomplete, setAutocomplete] = useState<
        AutoComplete | undefined
    >(currentAutocomplete)
    const [editableCode, setEditableCode] = useState<string>()
    const [latestCode, setLatestCode] = useState<string | undefined>()
    const [isEditorOpen, setIsEditorOpen] = useState(false)
    const [editorDefaultCode, setEditorDefaultCode] = useState<
        string | undefined
    >()
    const [editorVersion, setEditorVersion] = useState<string>('1.01')
    const [editorTitle, setEditorTitle] = useState('Instantsearch CSS')
    const [editorSave, setEditorSave] = useState<(type: string) => void>(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (type: string) => {}
    )
    const [editorLanguage, setEditorLanguage] = useState('css')

    const integrationId = currentIntegration?.id as string

    const [errors, setErrors] = useState<any>({
        selected_source: '',
        css_selector: '',
        selected_placement_region: '',
        mobile_screen_size: '',
    })

    const { showErrorAlert, showSuccessAlert } = useAlert()

    const [selectedPlacementRegion, setSelectedPlacementRegion] =
        useState<string>('header_bottom--global')

    async function createAutoComplete(): Promise<any> {
        setIsSaveLoading(true)
        try {
            const autocompleteCreate =
                await AutocompleteService.createAutocompleteSettings(
                    integrationId,
                    currentChannel?.id as number,
                    {
                        ...(newAutocomplete as AutoComplete),
                        is_enabled: false,
                        config: {
                            ...(newAutocomplete?.config as AutoCompleteConfig),
                            placement_region:
                                newAutocomplete?.config?.placement_region ??
                                'header_bottom--global',
                            hitsPerPage:
                                newAutocomplete?.config?.hitsPerPage ?? 6,
                            debug_mode:
                                newAutocomplete?.config?.debug_mode ?? false,
                        },
                    }
                )

            setCurrentAutocomplete(autocompleteCreate)
            showSuccessAlert('Autocomplete settings saved', 'Success')
        } catch (error: any) {
            showErrorAlert(
                error.message,
                'Unable to create Autocomplete settings'
            )
        } finally {
            setIsSaveLoading(false)
            setIsChanged(false)
        }
    }

    async function updateAutocompleteSettings(
        autocompleteSettings: AutoComplete
    ): Promise<any> {
        setIsSaveLoading(true)
        try {
            const autocompleteSave =
                await AutocompleteService.updateAutocompleteSettings(
                    integrationId,
                    currentChannel?.id as number,
                    currentAutocomplete?.id as string,
                    autocompleteSettings
                )

            if (
                autocompleteSave.is_enabled === autocompleteSettings.is_enabled
            ) {
                showSuccessAlert('Autocomplete settings updated', 'Success')
            }
            setCurrentAutocomplete(autocompleteSave)
            setIsChanged(false)
        } catch (error: any) {
            showErrorAlert('Unable to update Autocomplete settings', error)
        } finally {
            setIsSaveLoading(false)
        }
    }

    async function toggleAutocomplete(is_enabled: boolean): Promise<any> {
        setIsSaveLoading(true)
        try {
            let newAutocompleteData = undefined
            if (is_enabled) {
                newAutocompleteData =
                    await AutocompleteService.applyAutocompleteSettings(
                        integrationId,
                        currentChannel?.id as number,
                        currentAutocomplete?.id as string
                    )
            } else {
                newAutocompleteData =
                    await AutocompleteService.removeAutocompleteSettings(
                        integrationId,
                        currentChannel?.id as number,
                        currentAutocomplete?.id as string
                    )
            }
            setCurrentAutocomplete(newAutocompleteData)
            setAutocomplete(newAutocompleteData)
            setIsModalOpen(false)
            showSuccessAlert(
                `Autocomplete has been ${
                    is_enabled ? 'applied to' : 'removed from'
                } your theme`,
                'Success'
            )
        } catch (error: any) {
            if (error.includes('"html":"is too long')) {
                showErrorAlert(
                    `Unable to ${
                        is_enabled
                            ? 'apply Autcomplete to'
                            : 'remove Autcomplete from'
                    } theme`,
                    error
                )
            } else {
                showErrorAlert(
                    `Unable to ${
                        is_enabled
                            ? 'apply Autcomplete to'
                            : 'remove Autcomplete from'
                    } theme`,
                    error
                )
            }
        } finally {
            setIsModalOpen(false)
            setIsChanged(false)
            setIsSaveLoading(false)
        }
    }

    function handleSave(): void {
        if (currentAutocomplete?.id !== undefined) {
            updateAutocompleteSettings(newAutocomplete as AutoComplete)
        } else {
            createAutoComplete()
        }
    }

    const validate = function (): boolean {
        let isValid = true
        const newErrors = {
            selected_source: '',
            css_selector: '',
            selected_placement_region: '',
            mobile_screen_size: '',
        }

        if (
            (newAutocomplete?.config?.algolia_search_css_selector_mobile ===
                '' ||
                typeof newAutocomplete?.config
                    ?.algolia_search_css_selector_mobile === 'undefined') &&
            (Number.isNaN(newAutocomplete?.config?.mobile_screen_size) ||
                newAutocomplete?.config?.mobile_screen_size === undefined ||
                newAutocomplete?.config?.mobile_screen_size <= 0)
        ) {
            isValid = true
        } else if (
            newAutocomplete?.config?.algolia_search_css_selector_mobile !==
                '' &&
            (Number.isNaN(newAutocomplete?.config?.mobile_screen_size) ||
                newAutocomplete?.config?.mobile_screen_size === undefined ||
                newAutocomplete?.config?.mobile_screen_size <= 0)
        ) {
            isValid = false
            newErrors.mobile_screen_size =
                'Max mobile width is required if a mobile CSS selector is being used'
        } else {
            isValid = true
        }

        if (!newAutocomplete?.config?.algolia_search_css_selector) {
            newErrors.css_selector = 'CSS selector is required'
            isValid = false
        }

        if (!newAutocomplete?.config?.current_source) {
            newErrors.selected_source = 'Source is required'
            isValid = false
        }

        if (!selectedPlacementRegion) {
            newErrors.selected_placement_region = 'Placement region is required'
            isValid = false
        }

        setErrors(newErrors)
        return isValid
    }

    function shouldEnableSave(
        currentState: AutoCompleteConfig | undefined,
        newState: AutoCompleteConfig | undefined,
        excludedKeys: string[] = []
    ): boolean {
        if (currentState === undefined || newState === undefined) {
            return currentState === newState
        }

        const newKeys = Object.keys(newState).filter(
            (key) => !excludedKeys.includes(key)
        )

        for (const key of newKeys) {
            if ((newState as any)[key] !== (currentState as any)[key]) {
                return true
            }
        }

        return false
    }

    function handleToggle(is_enabled: boolean): void {
        if (is_enabled) {
            setIsModalOpen(true)
        } else {
            toggleAutocomplete(is_enabled)
        }
    }

    function handleCodeEdit(type: string): void {
        const javascriptVersion = newAutocomplete?.config?.javascript_version
        const cssVersion = newAutocomplete?.config?.css_version
        switch (type) {
            case 'css':
                setEditableCode(
                    typeof newAutocomplete === 'undefined' ||
                        typeof newAutocomplete.css === 'undefined' ||
                        newAutocomplete.css === null
                        ? defaultCode?.css
                        : (newAutocomplete.css as string)
                )
                setEditorDefaultCode(defaultCode?.css)
                setEditorSave((): ((field: string, field1: string) => void) => {
                    return (css: string, versionBeingSaved: string): void => {
                        AutocompleteService.updateCss(
                            newAutocomplete?.integration_id as string,
                            currentChannel?.id as number,
                            newAutocomplete?.id as string,
                            {
                                css,
                                config: { css_version: versionBeingSaved },
                            }
                        )
                            .then(() => {
                                setAutocomplete({
                                    ...(newAutocomplete as AutoComplete),
                                    css,
                                    config: {
                                        ...newAutocomplete?.config,
                                        css_version: versionBeingSaved,
                                    },
                                })

                                showSuccessAlert(
                                    'Successfully updated autocomplete CSS'
                                )
                            })
                            .catch((err) => {
                                if (
                                    err.includes(
                                        'The CSS or JavaScript is too long'
                                    )
                                ) {
                                    showErrorAlert(err)
                                } else {
                                    showErrorAlert(
                                        'Failed updating autocomplete CSS'
                                    )
                                }
                            })
                    }
                })
                if (typeof cssVersion === 'undefined' && versioning) {
                    setEditorVersion(versioning?.latestVersion)
                }

                if (typeof cssVersion !== 'undefined') {
                    setEditorVersion(cssVersion)
                }
                setLatestCode(versioning?.latestCss)
                setEditorLanguage('css')
                setEditorTitle('Autocomplete CSS')
                setIsEditorOpen(true)
                break

            case 'javascript':
                setEditableCode(
                    typeof newAutocomplete === 'undefined' ||
                        typeof newAutocomplete.javascript === 'undefined' ||
                        newAutocomplete.javascript === null
                        ? defaultCode?.javascript
                        : (newAutocomplete.javascript as string)
                )
                setEditorDefaultCode(defaultCode?.javascript)
                setEditorSave((): ((field: string, field1: string) => void) => {
                    return (
                        javascript: string,
                        versionBeingSaved: string
                    ): void => {
                        AutocompleteService.updateJavascript(
                            newAutocomplete?.integration_id as string,
                            currentChannel?.id as number,
                            newAutocomplete?.id as string,
                            {
                                javascript,
                                config: {
                                    javascript_version: versionBeingSaved,
                                },
                            }
                        )
                            .then(() => {
                                setAutocomplete({
                                    ...(newAutocomplete as AutoComplete),
                                    javascript,
                                    config: {
                                        ...newAutocomplete?.config,
                                        javascript_version: versionBeingSaved,
                                    },
                                })

                                showSuccessAlert(
                                    'Successfully updated autocomplete JavaScript'
                                )
                            })
                            .catch((err) => {
                                if (
                                    err.includes(
                                        'The CSS or JavaScript is too long'
                                    )
                                ) {
                                    showErrorAlert(err)
                                } else {
                                    showErrorAlert(
                                        'Failed updating autocomplete JavaScript'
                                    )
                                }
                            })
                    }
                })
                if (typeof javascriptVersion === 'undefined' && versioning) {
                    setEditorVersion(versioning?.latestVersion)
                }

                if (typeof javascriptVersion !== 'undefined') {
                    setEditorVersion(javascriptVersion)
                }
                setLatestCode(versioning?.latestJavascript)
                setEditorLanguage('javascript')
                setEditorTitle('Autocomplete JavaScript')
                setIsEditorOpen(true)
                break

            default:
        }
    }

    async function fetchDefaultCodeAndVersioning(): Promise<void> {
        const defaults = await AutocompleteService.getAutocompleteDefaultCode(
            integrationId,
            currentChannel?.id as number,
            currentAutocomplete?.id as string
        )
        setDefaultCode(defaults)

        const versions = await AutocompleteService.getAutocompleteVersioning(
            integrationId,
            currentChannel?.id as number,
            currentAutocomplete?.id as string
        )
        setVersioning(versions)

        if (
            typeof newAutocomplete?.css === 'undefined' ||
            typeof newAutocomplete?.javascript === 'undefined'
        ) {
            setAutocomplete({
                ...(newAutocomplete as AutoComplete),
                css: newAutocomplete?.css || defaults.css,
                javascript: newAutocomplete?.javascript || defaults.javascript,
                config: {
                    ...newAutocomplete?.config,
                    css_version:
                        newAutocomplete?.config?.css_version ||
                        versions.latestVersion,
                    javascript_version:
                        newAutocomplete?.config?.javascript_version ||
                        versions.latestVersion,
                },
            })
        }
    }

    useEffect(() => {
        setCurrentChannel(currentChannel)
    }, [currentChannel])

    useEffect(() => {
        if (typeof defaultCode === 'undefined') {
            fetchDefaultCodeAndVersioning()
        }

        setAutocomplete(currentAutocomplete)
    }, [currentAutocomplete])

    useEffect(() => {
        if (
            shouldEnableSave(
                currentAutocomplete?.config,
                newAutocomplete?.config,
                ['widget_id', 'placement_id']
            )
        ) {
            setIsChanged(true)
        }

        const formHasErrors = !Object.values(errors).every(
            (value) => value === ''
        )

        if (formHasErrors) {
            validate()
        }
    }, [newAutocomplete?.config, currentAutocomplete?.config])

    return (
        <>
            <EditCodeModal
                title={editorTitle}
                language={editorLanguage}
                editableCode={editableCode as string}
                isModalOpen={isEditorOpen}
                setIsModalOpen={setIsEditorOpen}
                defaultCode={editorDefaultCode as string}
                save={editorSave}
                currentVersion={editorVersion}
                latestVersion={versioning?.latestVersion as string}
                latestCode={latestCode as string}
                setEditableCode={setEditableCode}
                isFromAdminView={isFromAdminView}
            />

            <ApplyToThemeConfirm
                type={`Autocomplete`}
                isModalOpen={isModalOpen}
                isLoading={isSaveLoading}
                setIsModalOpen={setIsModalOpen}
                applyToTheme={toggleAutocomplete}
            />
            <Card className={stl`w-900`} fullBleed>
                <CardHeader className={stl`px-4 mt-4 mb-2`}>
                    <h1 className={stl`display-heading`}>Autocomplete</h1>
                </CardHeader>
                <FlexGrid className={stl`w-full px-4 mb-4`} distribution="fill">
                    <div>
                        <p>
                            Display multiple panels of results from different
                            data sources as soon as they start typing.
                        </p>
                        <Link href="#">What is Autocomplete?</Link>
                        {currentAutocomplete?.config?.current_source ===
                        undefined ? (
                            <p className={stl`mt-2`}>
                                <b>
                                    To enable Autocomplete for the first time,
                                    please configure the required settings.
                                </b>
                            </p>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={stl`flex justify-end`}>
                        <p className={stl`mr-2`}>
                            {currentAutocomplete?.is_enabled
                                ? 'Enabled'
                                : 'Disabled'}
                        </p>
                        <Toggle
                            checked={currentAutocomplete?.is_enabled}
                            disabled={
                                currentAutocomplete === undefined ||
                                currentAutocomplete.id === undefined ||
                                isSaveLoading ||
                                isFromAdminView()
                            }
                            onChange={(event: any): void => {
                                handleToggle(event.target.checked)
                            }}
                        />
                    </div>
                </FlexGrid>
                <FlexGrid
                    className={stl`w-full bg-grey-100 p-6`}
                    direction="column"
                    spacing="lg"
                >
                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Source *
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                description={
                                    <span>
                                        Source used to display products in
                                        Autocomplete. This field is required.
                                    </span>
                                }
                                state={{
                                    errors: [errors.selected_source],
                                    status: errors.selected_source
                                        ? 'invalid'
                                        : 'default',
                                }}
                            >
                                <SourceDropdown
                                    currentChannel={currentChannel}
                                    integration={currentIntegration}
                                    setSelectedSource={(
                                        current_source: string
                                    ): void => {
                                        setAutocomplete({
                                            ...(newAutocomplete as AutoComplete),
                                            config: {
                                                ...(newAutocomplete?.config as AutoCompleteConfig),
                                                current_source,
                                            },
                                        })
                                    }}
                                    selectedSource={
                                        newAutocomplete?.config?.current_source
                                    }
                                ></SourceDropdown>
                            </Field>
                        </div>
                    </FlexGrid>
                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Desktop CSS Selector *
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                className={stl`w-full`}
                                description={
                                    <span>
                                        Desktop CSS Selector for the search
                                        input. You might have to change this
                                        value to an element selector that works
                                        for your theme. This field is required
                                        and case sensitive.
                                    </span>
                                }
                                state={{
                                    errors: [errors.css_selector],
                                    status: errors.css_selector
                                        ? 'invalid'
                                        : 'default',
                                }}
                            >
                                <Input
                                    variant="small"
                                    value={
                                        newAutocomplete?.config
                                            ?.algolia_search_css_selector ?? ''
                                    }
                                    clearable
                                    onChange={(event: any): void => {
                                        setAutocomplete({
                                            ...(newAutocomplete as AutoComplete),
                                            config: {
                                                ...(newAutocomplete?.config as AutoCompleteConfig),
                                                algolia_search_css_selector:
                                                    event.target.value,
                                            },
                                        })
                                    }}
                                    placeholder="#quickSearch"
                                />
                            </Field>
                        </div>
                    </FlexGrid>
                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Mobile CSS Selector
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                className={stl`w-full`}
                                description={
                                    <span>
                                        Mobile CSS Selector for the search
                                        input. Only needed if different from
                                        Desktop CSS selector
                                    </span>
                                }
                            >
                                <Input
                                    variant="small"
                                    value={
                                        newAutocomplete?.config
                                            ?.algolia_search_css_selector_mobile ??
                                        ''
                                    }
                                    clearable
                                    onChange={(event: any): void => {
                                        setAutocomplete({
                                            ...(newAutocomplete as AutoComplete),
                                            config: {
                                                ...(newAutocomplete?.config as AutoCompleteConfig),
                                                algolia_search_css_selector_mobile:
                                                    event.target.value,
                                            },
                                        })

                                        if (event.target.value === '') {
                                            setAutocomplete({
                                                ...(newAutocomplete as AutoComplete),
                                                config: {
                                                    ...(newAutocomplete?.config as AutoCompleteConfig),
                                                    algolia_search_css_selector_mobile:
                                                        '',
                                                    mobile_screen_size: 0,
                                                },
                                            })
                                        } else {
                                            setAutocomplete({
                                                ...(newAutocomplete as AutoComplete),
                                                config: {
                                                    ...(newAutocomplete?.config as AutoCompleteConfig),
                                                    algolia_search_css_selector_mobile:
                                                        event.target.value,
                                                },
                                            })
                                        }
                                    }}
                                    placeholder=".navPages-quickSearch"
                                />
                            </Field>
                        </div>
                    </FlexGrid>
                    {typeof newAutocomplete?.config
                        ?.algolia_search_css_selector_mobile !== 'undefined' &&
                        newAutocomplete?.config
                            ?.algolia_search_css_selector_mobile !== '' && (
                            <FlexGrid className={stl`w-full`}>
                                <h2
                                    className={stl`display-subheading`}
                                    style={{
                                        width: '250px',
                                    }}
                                >
                                    Max Mobile Width
                                </h2>
                                <div className={stl`w-400 flex justify-start`}>
                                    <Field
                                        className={stl`w-full`}
                                        description={
                                            <span>
                                                Screen size that changes site to
                                                mobile view in px.
                                            </span>
                                        }
                                        state={{
                                            errors: [errors.mobile_screen_size],
                                            status: errors.mobile_screen_size
                                                ? 'invalid'
                                                : 'default',
                                        }}
                                    >
                                        <Input
                                            defaultValue={undefined}
                                            min={0}
                                            type="number"
                                            variant="small"
                                            value={
                                                newAutocomplete?.config
                                                    ?.mobile_screen_size ?? ''
                                            }
                                            clearable
                                            onChange={(event: any): void => {
                                                setAutocomplete({
                                                    ...(newAutocomplete as AutoComplete),
                                                    config: {
                                                        ...(newAutocomplete?.config as AutoCompleteConfig),
                                                        mobile_screen_size:
                                                            Number(
                                                                event.target
                                                                    .value
                                                            ),
                                                    },
                                                })
                                            }}
                                            placeholder="800"
                                        />
                                    </Field>
                                </div>
                            </FlexGrid>
                        )}
                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Placement region *
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                description={
                                    <span>
                                        Placement region to place Autocomplete
                                        for your theme. This field is required.
                                    </span>
                                }
                                state={{
                                    errors: [errors.selected_placement_region],
                                    status: errors.selected_placement_region
                                        ? 'invalid'
                                        : 'default',
                                }}
                            >
                                <RegionsDropdown
                                    templateFile="pages/home"
                                    setSelectedPlacementRegion={(
                                        placement_region: string
                                    ): void => {
                                        setAutocomplete({
                                            ...(newAutocomplete as AutoComplete),
                                            config: {
                                                ...(newAutocomplete?.config as AutoCompleteConfig),
                                                placement_region,
                                            },
                                        })
                                        setSelectedPlacementRegion(
                                            placement_region
                                        )
                                    }}
                                    selectedPlacementRegion={
                                        newAutocomplete?.config
                                            ?.placement_region ??
                                        selectedPlacementRegion
                                    }
                                ></RegionsDropdown>
                            </Field>
                        </div>
                    </FlexGrid>
                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Number of Products shown
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                description={
                                    <span>
                                        Number of products to show in search.
                                        Defaults to 6.
                                    </span>
                                }
                            >
                                <Input
                                    type="number"
                                    variant="small"
                                    value={
                                        newAutocomplete?.config?.hitsPerPage ??
                                        6
                                    }
                                    clearable
                                    onChange={(event): void => {
                                        setAutocomplete({
                                            ...(newAutocomplete as AutoComplete),
                                            config: {
                                                ...(newAutocomplete?.config as AutoCompleteConfig),
                                                hitsPerPage: event.target
                                                    .value as unknown as number,
                                            },
                                        })
                                    }}
                                />
                            </Field>
                        </div>
                    </FlexGrid>
                    <ShowOutOfStockCheckbox
                        showOutofStock={
                            newAutocomplete?.config?.show_out_of_stock_items ??
                            false
                        }
                        setShowOutofStock={(
                            showOutofStockItmes: boolean
                        ): void => {
                            setAutocomplete({
                                ...(newAutocomplete as AutoComplete),
                                config: {
                                    ...(newAutocomplete?.config as AutoCompleteConfig),
                                    show_out_of_stock_items:
                                        showOutofStockItmes,
                                },
                            })
                        }}
                    />
                    <IsDistinctCheckbox
                        isDistinct={
                            newAutocomplete?.config?.is_distinct ?? false
                        }
                        setIsDistinct={(isDistinct: boolean): void => {
                            setAutocomplete({
                                ...(newAutocomplete as AutoComplete),
                                config: {
                                    ...(newAutocomplete?.config as AutoCompleteConfig),
                                    is_distinct: isDistinct,
                                },
                            })
                        }}
                    />
                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Debug mode
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                description={
                                    <span>
                                        Debug mode enables Autocomplete results
                                        panel to stay after clicking away from
                                        search. Not recommended for use in a
                                        production environment.
                                    </span>
                                }
                            >
                                <Checkbox
                                    checked={
                                        newAutocomplete?.config?.debug_mode
                                    }
                                    onChange={(event: any): void => {
                                        setAutocomplete({
                                            ...(newAutocomplete as AutoComplete),
                                            config: {
                                                ...(newAutocomplete?.config as AutoCompleteConfig),
                                                debug_mode: event.target
                                                    .checked as boolean,
                                            },
                                        })
                                    }}
                                />
                            </Field>
                        </div>
                    </FlexGrid>
                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Edit CSS
                        </h2>
                        <div className={stl`w-400 justify-start`}>
                            <Button
                                disabled={isEmpty(newAutocomplete?.id)}
                                onClick={(): void => {
                                    handleCodeEdit('css')
                                }}
                                startIcon={Edit}
                                className="stl-mb-10"
                                size="large"
                                variant="neutral"
                            >
                                Edit
                            </Button>
                            <CodeUpdateAvailableBadge
                                version={newAutocomplete?.config?.css_version}
                                versioning={versioning as Versioning}
                            />
                            <p className="stl-display-caption stl-text-grey-600 stl-mt-2">
                                Add or edit the CSS used for the Autocomplete
                                widget.
                            </p>
                        </div>
                    </FlexGrid>
                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Edit JavaScript
                        </h2>
                        <div className={stl`w-400 justify-start`}>
                            <Button
                                disabled={isEmpty(newAutocomplete?.id)}
                                onClick={(): void => {
                                    handleCodeEdit('javascript')
                                }}
                                startIcon={Edit}
                                className="stl-mb-10"
                                size="large"
                                variant="neutral"
                            >
                                Edit
                            </Button>
                            <CodeUpdateAvailableBadge
                                version={
                                    newAutocomplete?.config?.javascript_version
                                }
                                versioning={versioning as Versioning}
                            />
                            <p className="stl-display-caption stl-text-grey-600 stl-mt-2">
                                Add or edit the JavaScript used for the
                                Autocomplete widget.
                            </p>
                        </div>
                    </FlexGrid>
                    <FlexGrid className={stl`w-full pr-25 flex justify-end`}>
                        <Button
                            variant="primary"
                            onClick={(): void => {
                                if (validate()) {
                                    handleSave()
                                } else {
                                    showErrorAlert(
                                        'Please fill in all required Autocomplete fields',
                                        'Error'
                                    )
                                }
                            }}
                            disabled={!isChanged || isFromAdminView()}
                            loading={isSaveLoading}
                        >
                            Save
                        </Button>
                    </FlexGrid>
                </FlexGrid>
            </Card>
        </>
    )
}
