import type { Entity } from '../../types/database/entities.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const EntityService = {
    async createEntity(
        integrationId: string,
        instantsearchId: string,
        data: Entity
    ): Promise<Entity> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/instantsearch/${instantsearchId}/entity`,
            REQUEST_TYPES.post,
            JSON.stringify(data)
        )
    },
    async fetchEntity(
        integrationId: string,
        instantsearchId: string,
        entityId: string
    ): Promise<Entity> {
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/instantsearch/${instantsearchId}/entity/${entityId}`,
            REQUEST_TYPES.get
        )
    },
    async updateEntity(integrationId: string, entity: Entity): Promise<Entity> {
        // eslint-disable-next-line no-param-reassign
        delete entity.last_modified

        // eslint-disable-next-line no-param-reassign
        delete entity.created_at

        return await makeRequest(
            `/api/v1/integrations/${integrationId}/instantsearch/${entity.instantsearch_id}/entity/${entity.id}`,
            REQUEST_TYPES.put,
            JSON.stringify(entity)
        )
    },
    async applyEntity(
        integrationId: string,
        instantsearchId: string,
        data: Entity
    ): Promise<Entity> {
        // eslint-disable-next-line no-param-reassign
        delete data.created_at
        // eslint-disable-next-line no-param-reassign
        delete data.last_modified
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/instantsearch/${instantsearchId}/entity/${data.id}/apply`,
            REQUEST_TYPES.post,
            JSON.stringify(data)
        )
    },
    async removeEntity(
        integrationId: string,
        instantsearchId: string,
        data: Entity
    ): Promise<Entity> {
        // eslint-disable-next-line no-param-reassign
        delete data.created_at
        // eslint-disable-next-line no-param-reassign
        delete data.last_modified
        return await makeRequest(
            `/api/v1/integrations/${integrationId}/instantsearch/${instantsearchId}/entity/${data.id}/remove`,
            REQUEST_TYPES.delete,
            JSON.stringify(data)
        )
    },
}

export default EntityService
