import { Card, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useState, useEffect } from 'react'

import AlgoliaAuthService from '../../services/algolia/algoliaAuthService'
import JwtService from '../../services/common/jwtService'
import { setToken } from '../../utils/tokenManager'
import { isEmpty } from '../../utils/utils'
import { useAuth } from '../AuthContext'
import { PlatformAuth } from '../algolia'

export const AlgoliaLoginVerify: FunctionComponent = (): any => {
    const { platform, isPlatformAuthed, setIsPlatformAuthed, setAppexJwt } =
        useAuth()

    const [cookieAllowed, setCookieAllowed] = useState<boolean>(true)

    useEffect(() => {
        async function handleMessage(event: MessageEvent): Promise<void> {
            if (
                event.origin.toLowerCase() !==
                window.location.origin.toLowerCase()
            ) {
                return
            }

            if (event.data.authStatus === 'success') {
                AlgoliaAuthService.updateStorage(true)
                setIsPlatformAuthed(true)
            }

            if (
                !isEmpty(event.data.refresh) &&
                !isEmpty(platform) &&
                !isEmpty(platform.id)
            ) {
                // token we have here may not have been updated with the latest user info after auth. refresh token
                const tokenResponse = await JwtService.refreshToken()
                if (!isEmpty(tokenResponse.token)) {
                    setToken(tokenResponse.token)
                    setAppexJwt(tokenResponse.token)
                }
            }
        }
        window.addEventListener('message', handleMessage)

        if (!AlgoliaAuthService.checkAuthStatus()) {
            AlgoliaAuthService.updateStorage(false)
            setIsPlatformAuthed(false)
        } else {
            setIsPlatformAuthed(true)
        }

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    useEffect(() => {
        if (!navigator.cookieEnabled) {
            setCookieAllowed(false)
        } else {
            setCookieAllowed(true)
        }
    }, [])

    return (
        <>
            {!isPlatformAuthed && (
                <Card.Header className={stl`border-b border-grey-200 p-6`}>
                    <FlexGrid
                        direction="column"
                        spacing="md"
                        alignment="center"
                        className="stl-w-full"
                    >
                        <h1 className="stl-display-heading stl-text-grey-900">
                            Login to your Algolia account to continue
                        </h1>
                        {!cookieAllowed && (
                            <div className="code-error">
                                Please enable third-party cookies for the best
                                experience. See{' '}
                                <a
                                    href="https://www.algolia.com/policies/cookies/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    here
                                </a>{' '}
                                for instructions.
                            </div>
                        )}

                        <PlatformAuth
                            setIsPlatformAuthed={setIsPlatformAuthed}
                            isPlatformAuthed={isPlatformAuthed}
                            cookieAllowed={cookieAllowed}
                        />
                    </FlexGrid>
                </Card.Header>
            )}
        </>
    )
}
