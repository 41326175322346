import { ProgressSpinner } from '@algolia/satellite'
import React from 'react'

type LoaderProps = {
    message?: string
}

export const Loader: React.FunctionComponent<LoaderProps> = (
    props: LoaderProps
) => {
    return (
        <div className="stl-flex stl-flex-col stl-items-center stl-h-full stl-justify-center">
            {props.message && <span className="stl-mb-4">{props.message}</span>}
            <ProgressSpinner size={48} thickness={4} />
        </div>
    )
}
