import { Input, Field, Button } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BigCommerceService from '../../services/bigCommerce/bigCommerceService'
import AlgoliaService from '../../services/database/algoliaService'
import IntegrationService from '../../services/database/integrationService'
import SourceService from '../../services/database/sourceService'
import type { AlgoliaApplication } from '../../types/algolia/algoliaApplication.type'
import type { Indexes } from '../../types/algolia/indexResponse.type'
import type { BcStoreInfoResponse } from '../../types/bigCommerce/storeInfoResponse.type'
import type { IntegrationType } from '../../types/database/integration.type'
import type { SourceType } from '../../types/database/source.type'
import { RECORD_TYPES } from '../../utils/recordTypes'
import { isEmpty } from '../../utils/utils'
import { useAlert } from '../AlertContext'
import { useAuth } from '../AuthContext'
import { RecordTypeRadioButton } from '../common/recordTypeRadioButton'
import { SuccessThreshold } from '../common/successThreshold'

import { CurrencyOptions } from './currencyOptions'

type Props = {
    integration: IntegrationType
    source?: SourceType
    channelId: number
    loading: boolean
    setSource: (src: SourceType) => void
    updateSource: (src: SourceType) => void
    setLoading: (isLoading: boolean) => void
    setShowBcSetup: (show: boolean) => void
    setShowIndexStep: (show: boolean) => void
}

export const SourceForm: FunctionComponent<Props> = (props: Props) => {
    const navigate = useNavigate()
    const { showErrorAlert, showSuccessAlert } = useAlert()

    const {
        integration,
        source,
        channelId,
        loading,
        setSource,
        updateSource,
        setLoading,
        setShowBcSetup,
        setShowIndexStep,
    } = props
    const { isPlatformAuthed, platform } = useAuth()
    const [hasAppAccess, setHasAppAccess] = useState(false)
    const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>([])
    const [indexName, setIndexName] = useState('BigCommerce')
    const [successThreshold, setSuccessThreshold] = useState(100)
    const [recordType, setRecordType] = useState<string>(RECORD_TYPES.product)
    const [selectedChannelName, setSelectedChannelName] = useState<string>()
    const [usedIndexNames, setUsedIndexNames] = useState<
        Indexes[] | undefined
    >()

    const [errors, setErrors] = React.useState({
        indexName: '',
    })
    const [storeInfo, setStoreInfo] = useState<
        BcStoreInfoResponse | undefined
    >()

    const fetchStoreData = async (): Promise<void> => {
        setStoreInfo(await BigCommerceService.fetchStoreInfo())
    }

    const fetchChannel = async (): Promise<void> => {
        const channelsList = await BigCommerceService.fetchChannels()
        const channelData = channelsList.data.filter(
            (activeChannel: any) => activeChannel.id === channelId
        )

        setSelectedChannelName(channelData[0]?.name)
    }

    const fetchIndexes = async (): Promise<void> => {
        const indexResponse = await IntegrationService.fetchAppIndexes(
            integration?.id as string
        )
        const indexNames = indexResponse.items ?? []
        for (const src of integration?.sources ?? []) {
            if (typeof src?.config?.indexName !== 'undefined') {
                indexNames.push({ name: src.config.indexName })
            }
        }

        setUsedIndexNames(indexNames)
    }

    const validate = (): boolean => {
        let isValid = true
        const newErrors = {
            indexName: '',
        }

        if (isEmpty(indexName) || indexName === '') {
            newErrors.indexName = 'Index name is required'
            isValid = false
        }
        if (isEmpty(recordType)) {
            isValid = false
        }
        if (isEmpty(selectedCurrencies) || selectedCurrencies.length === 0) {
            isValid = false
        }
        if (isEmpty(successThreshold)) {
            isValid = false
        }

        // update source
        if (
            !isEmpty(source) &&
            source?.config?.indexName !== indexName &&
            usedIndexNames &&
            usedIndexNames.filter((index: Indexes) => {
                return index.name === indexName
            }).length > 0
        ) {
            newErrors.indexName =
                'Index name is already in use for this Algolia application'
            isValid = false
        }

        // create new source
        if (
            isEmpty(source) &&
            usedIndexNames &&
            usedIndexNames.filter((index: Indexes) => {
                return index.name === indexName
            }).length > 0
        ) {
            newErrors.indexName =
                'Index name is already in use for this Algolia application'
            isValid = false
        }

        setErrors(newErrors)
        return isValid
    }

    const isSaveButtonDisabled = (): boolean => {
        if (!isPlatformAuthed || !hasAppAccess) {
            return true
        }

        if (isEmpty(indexName) || indexName === '') {
            return true
        }
        if (isEmpty(recordType)) {
            return true
        }
        if (isEmpty(selectedCurrencies) || selectedCurrencies.length === 0) {
            return true
        }
        if (isEmpty(successThreshold)) {
            return true
        }

        return false
    }

    const verifyUserHasAccessToApp = async (retryCount = 1): Promise<void> => {
        try {
            const userApplications = await AlgoliaService.getApplications()
            if (
                userApplications.filter((application: AlgoliaApplication) => {
                    return application.id === integration?.algolia_app_id
                }).length > 0
            ) {
                setHasAppAccess(true)
                return
            }

            showErrorAlert(
                `You do not have access to the algolia application used for this integration - ${integration?.algolia_app_id}`,
                'Error'
            )
            setHasAppAccess(false)
        } catch (err) {
            if (retryCount === 3) {
                return
            }

            verifyUserHasAccessToApp(retryCount + 1)
        }
    }

    const saveNewSource = (src: SourceType): void => {
        setLoading(true)
        SourceService.create(src, integration?.id as string)
            .then((sourceResponse) => {
                setSource(sourceResponse)
                showSuccessAlert('Source has been saved')
                setShowIndexStep(true)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                let errorMessage = 'There was an error finalizing your source'
                if (error.includes('Too many indices')) {
                    errorMessage = error
                }
                showErrorAlert(errorMessage, 'Error')
            })
    }

    useEffect(() => {
        if (isPlatformAuthed && !isEmpty(integration)) {
            verifyUserHasAccessToApp()
        }
    }, [isPlatformAuthed, integration])

    useEffect((): void => {
        if (!isEmpty(source) && selectedCurrencies.length === 0) {
            setIndexName(source?.config?.indexName ?? indexName)
            // If record type is empty then that means it was created before record type was added and we need to default to variant
            setRecordType(source?.config?.recordType ?? RECORD_TYPES.variant)
            setSelectedCurrencies(source?.config?.currencies ?? [])
            setSuccessThreshold(source?.config?.successThreshold ?? 100)
        }
    }, [source])

    useEffect((): void => {
        if (!isEmpty(platform) && !isEmpty(integration)) {
            fetchStoreData()
            fetchChannel()
            fetchIndexes()
        }
    }, [integration])

    return (
        <>
            <Field
                label="Index name"
                className="stl-w-full stl-text-left"
                state={{
                    errors: [errors.indexName],
                    status: errors.indexName ? 'invalid' : 'default',
                }}
            >
                <Input
                    className="stl-w-full"
                    placeholder="Enter your index name"
                    disabled={!isPlatformAuthed}
                    value={indexName}
                    maxLength={255}
                    onChange={(event): void => {
                        setIndexName(event.target.value)
                        setErrors({
                            ...errors,
                            indexName: '',
                        })
                    }}
                />
            </Field>
            <RecordTypeRadioButton
                sourceId={source?.id}
                disabled={!hasAppAccess || !isPlatformAuthed}
                recordType={recordType}
                setRecordType={setRecordType}
            />
            <CurrencyOptions
                selectedCurrencies={selectedCurrencies}
                disabled={!hasAppAccess || !isPlatformAuthed}
                setSelectedCurrencies={setSelectedCurrencies}
            />
            <SuccessThreshold
                disabled={!isPlatformAuthed}
                successThreshold={successThreshold}
                setSuccessThreshold={setSuccessThreshold}
            />
            <Button
                className="stl-w-full stl-xenon-button"
                size="large"
                loading={loading}
                disabled={isSaveButtonDisabled()}
                onClick={(): void => {
                    if (!validate()) {
                        return
                    }

                    if (isEmpty(source)) {
                        saveNewSource({
                            channel_id: Number(channelId),
                            config: {
                                indexName,
                                channelName: selectedChannelName,
                                currencies:
                                    selectedCurrencies.length > 0
                                        ? selectedCurrencies
                                        : [storeInfo?.currency as string],
                                recordType,
                                successThreshold,
                            },
                        })
                    } else {
                        updateSource({
                            ...source,
                            channel_id: Number(channelId),
                            config: {
                                indexName,
                                channelName: selectedChannelName,
                                currencies:
                                    selectedCurrencies.length > 0
                                        ? selectedCurrencies
                                        : [storeInfo?.currency as string],
                                successThreshold,
                            },
                        })
                    }
                }}
            >
                Save
            </Button>
            <Button
                className="stl-w-full"
                size="large"
                onClick={(): void => {
                    if (isEmpty(source)) {
                        navigate(`/bigcommerce/${integration?.id}/channels`)
                    } else {
                        setShowBcSetup(false)
                    }
                }}
            >
                Cancel
            </Button>
        </>
    )
}
