import type {
    DateRangePickerCompleteTimeRange,
    DateRangePickerProps,
    DateRangePickerReducerAction,
    DateRangePickerReducerState,
    DateRangePickerTimeRange,
} from '@algolia/satellite'
import {
    isCompleteTimeRange,
    Button,
    DateRangePicker,
} from '@algolia/satellite'
import type {
    Dispatch,
    FunctionComponent,
    JSXElementConstructor,
    ReactElement,
} from 'react'
import { useEffect, useState } from 'react'
import { Calendar } from 'react-feather'

import { isEmpty } from '../../../utils/utils'

import { DateRangePanel } from './dateRangePanel'
import { formatDateTimeRange } from './helpers'
import { TimeRangePanel } from './timeRangePanel'

type RightPanelTypes = 'dateRangePanel' | 'timeRangePanel'

export type RightSidePanelComponentArgs = {
    state: DateRangePickerReducerState
    dispatch: Dispatch<DateRangePickerReducerAction>
}
type MyDateRangePickerProps = {
    range: DateRangePickerTimeRange | null
    editableYear: boolean
    modalPlacement: 'bottom' // Update with the correct type
    calendarProps: {
        numberOfMonths: number
        disabled?: { after: Date; before?: Date }
    }
    onAction: (action: any, state: any) => void
    validate: (state: any) => any
    renderRightPanel: (args: any) => React.ReactElement
    renderTarget?: ({ toggle }: { toggle: () => void }) => React.ReactElement
}

type Props = {
    minDateTime?: Date
    maxDateTime?: Date
    rightPanelType: RightPanelTypes
    dateRange: DateRangePickerTimeRange | null
    setDateRange: (range: DateRangePickerTimeRange) => any
}

export const DateRange: FunctionComponent<Props> = (props): any => {
    const {
        minDateTime,
        maxDateTime,
        rightPanelType,
        dateRange,
        setDateRange,
    } = props
    const [isValid, setIsValid] = useState(true)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [selectedDateRange, setSelectedDateRange] = useState<
        DateRangePickerProps['range']
    >({
        start: dateRange?.start as Date,
        end: dateRange?.end as Date,
    })

    const formatDateTimes = (state: any): any => {
        let min
        let max
        const start = new Date(
            new Date(state.candidateRange.start as Date).setSeconds(0, 0)
        )

        const end = new Date(
            new Date(state.candidateRange.end as Date).setSeconds(0, 0)
        )
        if (typeof minDateTime !== 'undefined') {
            min = new Date(new Date(minDateTime as Date).setSeconds(0, 0))
        }
        if (typeof maxDateTime !== 'undefined') {
            max = new Date(new Date(maxDateTime as Date).setSeconds(0, 0))
        }

        const formattedMin = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'UTC',
        }).format(minDateTime)

        const formattedMax = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'UTC',
        }).format(maxDateTime)

        return {
            start,
            end,
            min,
            max,
            formattedMin,
            formattedMax,
        }
    }

    const validateDateTimePickerRange = (state: any): any => {
        setIsValid(true)
        setErrorMessage(undefined)

        if (
            isEmpty(state.candidateRange.start?.toDateString()) ||
            isEmpty(state.candidateRange.end?.toDateString())
        ) {
            setIsValid(false)
            setErrorMessage(`Invalid range`)
            return [new Error('Invalid time range')]
        }

        setSelectedDateRange(state.candidateRange)

        if (
            typeof minDateTime === 'undefined' &&
            typeof maxDateTime === 'undefined'
        ) {
            return true
        }

        const { start, end, min, max, formattedMin, formattedMax } =
            formatDateTimes(state)

        if (typeof min !== 'undefined' && min.getTime() > start.getTime()) {
            setIsValid(false)
            setErrorMessage(
                `Start date time must be greater than start time of the run(${formattedMin})`
            )

            return [new Error('Invalid time range')]
        }

        if (typeof max !== 'undefined' && start.getTime() > max.getTime()) {
            setIsValid(false)
            setErrorMessage(
                `Start date time must be less than end time of the run(${formattedMax})`
            )

            return [new Error('Invalid time range')]
        }

        if (typeof max !== 'undefined' && max.getTime() < end.getTime()) {
            setIsValid(false)
            setErrorMessage(
                `End date time must be less than end time of the run(${formattedMax})`
            )

            return [new Error('Invalid time range')]
        }

        if (typeof min !== 'undefined' && end.getTime() < min.getTime()) {
            setIsValid(false)
            setErrorMessage(
                `End date time must be less than end time of the run(${formattedMax})`
            )

            return [new Error('Invalid time range')]
        }

        if (
            typeof min !== 'undefined' &&
            typeof max !== 'undefined' &&
            (start < min || end > max)
        ) {
            setIsValid(false)
            setErrorMessage(
                `Range must be within runs start and finish times(${formattedMin} - ${formattedMax})`
            )

            return [new Error('Invalid time range')]
        }

        return true
    }

    useEffect(() => {
        setSelectedDateRange({
            start: dateRange?.start as Date,
            end: dateRange?.end as Date,
        })
    }, [dateRange])

    const dateRangePickerProps: MyDateRangePickerProps = {
        range: selectedDateRange,
        modalPlacement: 'bottom',
        editableYear: true,
        calendarProps: {
            disabled: {
                after: new Date(),
            },
            numberOfMonths: 1,
        },
        onAction: (action, state) => {
            if (action.type !== 'apply') {
                return
            }
            setDateRange(state.candidateRange)
        },
        validate: validateDateTimePickerRange,
        renderRightPanel: (args) => {
            switch (rightPanelType) {
                case 'timeRangePanel':
                    return (
                        <TimeRangePanel
                            errorMessage={errorMessage}
                            isValid={isValid}
                            panelArgs={args}
                            dateRange={
                                selectedDateRange as DateRangePickerCompleteTimeRange
                            }
                        />
                    )
                case 'dateRangePanel':
                default:
                    return <DateRangePanel panelArgs={args} />
            }
        },
    }

    if (['timeRangePanel'].includes(rightPanelType)) {
        dateRangePickerProps.renderTarget = ({
            toggle,
        }): ReactElement<any, JSXElementConstructor<any> | string> => (
            <Button onClick={toggle} startIcon={Calendar} size={'medium'}>
                {isCompleteTimeRange(dateRange) ? (
                    formatDateTimeRange(dateRange)
                ) : (
                    <span>Enter a date period&hellip;</span>
                )}
            </Button>
        )
    }

    return (
        <>
            <DateRangePicker {...dateRangePickerProps} />
        </>
    )
}
