import { Button } from '@algolia/satellite'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import AlgoliaAuthService from '../../services/algolia/algoliaAuthService'
import { isEmpty } from '../../utils/utils'
import { useAlert } from '../AlertContext'
import { useAuth } from '../AuthContext'

type Props = {
    isPlatformAuthed: boolean
    setIsPlatformAuthed: (isAuthenticated: boolean) => void
    cookieAllowed: boolean
}

export const PlatformAuth: React.FunctionComponent<Props> = (props: Props) => {
    const { platform, appexJwt } = useAuth()
    const [searchParams] = useSearchParams()
    const [seconds, setSeconds] = useState(3)
    const [showButton, setShowButton] = useState(true)
    const [authError, setAuthError] = useState(false)
    const { showErrorAlert, showPersistentAlert } = useAlert()

    function checkAuthStatusWrapper(): void {
        if (!isEmpty(platform) && platform.integration_id === null) {
            AlgoliaAuthService.removeAuth()
            props.setIsPlatformAuthed(false)
            setShowButton(true)
        }
    }

    useEffect(() => {
        const successParam = searchParams.get('success')

        switch (successParam) {
            case '0':
                setAuthError(true)
                setShowButton(false)
                props.setIsPlatformAuthed(false)
                if (window.opener && origin) {
                    window.opener.postMessage({ authStatus: 'fail' }, origin)
                }
                break
            case '1':
                if (
                    !isEmpty(searchParams.get('refresh')) &&
                    window.opener &&
                    origin
                ) {
                    window.opener.postMessage(
                        { refresh: searchParams.get('refresh') as string },
                        origin
                    )
                }
                setShowButton(false)
                props.setIsPlatformAuthed(true)
                if (window.opener && origin) {
                    window.opener.postMessage({ authStatus: 'success' }, origin)
                }

                break
            default:
                setShowButton(true)
                checkAuthStatusWrapper()
        }
    }, [])

    function closeWindow(): void {
        showPersistentAlert(
            'Success',
            `Successfully authenticated with Algolia. Window will automatically close in 3 seconds`,
            'green'
        )

        // auto close window after 3 secs after successful auth to algolia
        setInterval(() => {
            setSeconds(seconds - 1)
            if (seconds <= 0) {
                window.self.close()
            }
        }, 1000)
    }

    function login(): void {
        window.open(
            `/api/v1/algolia-auth?token=${appexJwt}`,
            'appex',
            'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100'
        )
    }

    return (
        <>
            {showButton && !props.isPlatformAuthed && (
                <Button
                    disabled={
                        isEmpty(platform) ||
                        (typeof props.cookieAllowed !== 'undefined' &&
                            !props.cookieAllowed)
                    }
                    variant="primary"
                    onClick={(): void => {
                        login()
                    }}
                >
                    Login With Algolia
                </Button>
            )}

            {!showButton && closeWindow()}

            {authError &&
                showErrorAlert('Sorry error occured please try again', 'Error')}
        </>
    )
}
