import * as Sentry from '@sentry/react'
import React from 'react'

import '@algolia/satellite/satellite.min.css'
import './assets/css/App.css'
import { AuthProvider } from './components/AuthContext'
import { NotificationBanner } from './components/common/notificationBanner'
import { Router } from './routes'

const App: React.FunctionComponent = () => {
    return (
        <div className="app stl-bg-grey-50">
            <AuthProvider>
                <>
                    <NotificationBanner />
                    <Router />
                </>
            </AuthProvider>
        </div>
    )
}

export default Sentry.withProfiler(App)
