import {
    Badge,
    Button,
    Card,
    CardHeader,
    Field,
    FlexGrid,
    Modal,
    ModalSection,
    stl,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { ArrowUpCircle } from 'react-feather'

import RecommendService from '../../../services/theming/recommendService'
import type { BcChannel } from '../../../types/bigCommerce/channelsRespone.type'
import type { IntegrationType } from '../../../types/database/integration.type'
import type {
    Recommend,
    RecommendConfig as RecommendConfigType,
} from '../../../types/database/recommend.type'
import type { RecommendVersioning } from '../../../types/theming/versioning.type'
import { useAlert } from '../../AlertContext'
import { Loader } from '../../common/loader'
import { SourceDropdown } from '../../common/sourceDropdown'

type Props = {
    currentChannel: BcChannel | undefined
    currentIntegration: IntegrationType | null | undefined
    currentRecommend: Recommend | undefined
    setCurrentRecommend: (recommend: Recommend) => void
    isFromAdminView: () => boolean
    isMultiChannel: boolean
}

export const RecommendConfig: FunctionComponent<Props> = (props: Props) => {
    const {
        currentChannel,
        currentIntegration,
        currentRecommend,
        setCurrentRecommend,
        isFromAdminView,
        isMultiChannel,
    } = props

    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false)
    const [newRecommend, setNewRecommend] = useState<Recommend | undefined>(
        currentRecommend
    )
    const integrationId = currentIntegration?.id as string

    const { showErrorAlert, showSuccessAlert } = useAlert()
    const [errors, setErrors] = useState({
        selected_source: '',
    })
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [versioning, setVersioning] = useState<RecommendVersioning>()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    async function createRecommend(): Promise<void> {
        setIsSaveLoading(true)

        try {
            const recommendCreate =
                await RecommendService.createRecommendSettings(
                    integrationId,
                    currentChannel?.id as number,
                    {
                        ...(newRecommend as Recommend),
                        is_enabled: false,
                        config: {
                            ...newRecommend?.config,
                        },
                    }
                )

            setCurrentRecommend(recommendCreate)
            applyRecommend(recommendCreate as Recommend)
        } catch (error: any) {
            showErrorAlert(error.message, 'Error creating recommend settings')
            setIsSaveLoading(false)
        }
    }

    async function applyRecommend(recommend: Recommend): Promise<void> {
        setIsSaveLoading(true)
        try {
            const recommendApply =
                await RecommendService.applyRecommendSettings(
                    recommend as Recommend
                )
            setCurrentRecommend(recommendApply)
            setNewRecommend(recommendApply)
            setIsChanged(false)
            showSuccessAlert('Recommend template applied successfully')
        } catch (error: any) {
            showErrorAlert(error.message, 'Error applying recommend settings')
        } finally {
            setIsSaveLoading(false)
        }
    }

    async function updateRecommend(): Promise<void> {
        setIsSaveLoading(true)
        try {
            const recommendUpdate =
                await RecommendService.updateRecommendSettings({
                    ...(newRecommend as Recommend),
                    config: {
                        ...newRecommend?.config,
                    },
                })
            setCurrentRecommend(recommendUpdate)
            setNewRecommend(recommendUpdate)
            showSuccessAlert('Recommend settings updated successfully')
            setIsChanged(false)
        } catch (error: any) {
            showErrorAlert(error.message, 'Error updating recommend settings')
        } finally {
            setIsSaveLoading(false)
        }
    }

    async function removeRecommend(): Promise<void> {
        setIsSaveLoading(true)

        try {
            const removedRec = await RecommendService.removeRecommendSettings(
                currentRecommend as Recommend
            )
            setCurrentRecommend(removedRec)
            setNewRecommend(removedRec)
            showSuccessAlert('Recommend settings removed successfully')
        } catch (error: any) {
            showErrorAlert(error.message, 'Error removing recommend settings')
        } finally {
            setIsSaveLoading(false)
            setIsModalOpen(false)
        }
    }

    function updateRecommendVersioning(): void {
        setIsSaveLoading(true)
        RecommendService.updateRecommendSettings({
            ...currentRecommend,
            config: {
                ...currentRecommend?.config,
                current_version: versioning?.latestVersion,
            },
        } as Recommend)
            .then((data) => {
                if (typeof data.error !== 'undefined') {
                    showErrorAlert(data.error, 'Error')
                    setIsSaveLoading(false)

                    return
                }
                setCurrentRecommend(data)
                setNewRecommend(data)
                setIsSaveLoading(false)
            })
            .catch(() => {
                setIsSaveLoading(false)
                showErrorAlert(
                    'There was an error upgrading your Recommend settings.',
                    'Error'
                )
            })
            .finally(() => {
                setIsSaveLoading(false)
                showSuccessAlert('Recommend version updated')
            })
    }

    async function getRecommendVersioning(): Promise<void> {
        setVersioning(
            await RecommendService.getRecommendVersioning(
                currentRecommend as Recommend
            )
        )
    }

    const validate = function (): boolean {
        let isValid = true
        const newErrors = {
            selected_source: '',
        }

        if (!newRecommend?.config?.current_source) {
            newErrors.selected_source = 'Please select a source'
            isValid = false
        }

        setErrors(newErrors)
        return isValid
    }

    const handleSave = async function (): Promise<void> {
        if (validate()) {
            if (currentRecommend) {
                await updateRecommend()
            } else {
                await createRecommend()
            }
        }
    }

    function shouldEnableSave(
        currentState: RecommendConfigType | undefined,
        newState: RecommendConfigType | undefined
    ): boolean {
        if (
            typeof currentState === 'undefined' ||
            typeof newState === 'undefined'
        ) {
            return currentState === newState
        }

        for (const key in newState) {
            if ((currentState as any)[key] !== (newState as any)[key]) {
                return true
            }
        }

        return false
    }

    function shouldUpdateButtonShow(): boolean {
        if (
            currentRecommend?.is_enabled &&
            currentRecommend?.config?.current_version &&
            versioning?.latestVersion &&
            currentRecommend?.config?.current_version <
                versioning?.latestVersion
        ) {
            return true
        }

        return false
    }

    useEffect(() => {
        if (typeof currentRecommend?.id !== 'undefined') {
            getRecommendVersioning()
        }
    }, [currentRecommend])

    useEffect(() => {
        if (shouldEnableSave(currentRecommend?.config, newRecommend?.config)) {
            setIsChanged(true)
        }

        const formHasErrors = !Object.values(errors).every(
            (value) => value === ''
        )

        if (formHasErrors) {
            validate()
        }
    }, [newRecommend?.config, currentRecommend?.config])

    return (
        <>
            <Modal
                open={isModalOpen}
                title={`Remove Recommend from your channel?`}
                animate={true}
                onDismiss={(): void => setIsModalOpen(false)}
                centerY={true}
            >
                <ModalSection className="stl-mb-2">
                    {isSaveLoading && (
                        <>
                            <Loader />
                            <div className="stl-mb-5 stl-justify-center">
                                {`Removing Recommend from your channel...`}
                            </div>
                        </>
                    )}
                    {!isSaveLoading && (
                        <div className="stl-mb-5 stl-justify-center">
                            {`Are you sure you want to remove Recommend for this channel?`}
                        </div>
                    )}
                    <FlexGrid
                        direction="row"
                        alignment="center"
                        spacing="md"
                        className="stl-mt-4"
                    >
                        <Button
                            disabled={isSaveLoading}
                            variant="destructive"
                            size="medium"
                            onClick={(): void => {
                                removeRecommend()
                            }}
                        >
                            Yes, remove Recommend
                        </Button>
                        <Button
                            variant="neutral"
                            size="medium"
                            onClick={(): void => setIsModalOpen(false)}
                        >
                            Cancel
                        </Button>
                    </FlexGrid>
                </ModalSection>
            </Modal>
            <Card className={stl`w-900`} fullBleed>
                <CardHeader className={stl`px-4 mt-4 mb-2`}>
                    <h1 className={stl`display-heading`}>Recommend</h1>
                    <FlexGrid className={stl`justify-end`}>
                        {shouldUpdateButtonShow() && (
                            <Badge
                                className="stl-capitalize"
                                variant={'red'}
                                size="small"
                                style={{ marginLeft: '20px' }}
                            >
                                Updates Available
                            </Badge>
                        )}
                    </FlexGrid>
                </CardHeader>
                <FlexGrid className={stl`w-full px-4 mb-4`} distribution="fill">
                    <div>
                        <p>
                            Maximize catalog exposure and drive cross-sell by
                            selecting one of your
                            <a
                                href={`https://www.algolia.com/apps/${currentIntegration?.algolia_app_id}/recommend`}
                                target="_blank"
                            >
                                {` Algolia Recommend `}
                            </a>
                            models. After saving, go to your
                            <a
                                href={
                                    isMultiChannel
                                        ? `https://store-${currentIntegration?.config?.bcStoreHash}.mybigcommerce.com/manage/page-builder/?channelId=${currentChannel?.id}`
                                        : `https://store-${currentIntegration?.config?.bcStoreHash}.mybigcommerce.com/manage/page-builder`
                                }
                                target="_blank"
                            >
                                {` theme editor `}
                            </a>
                            and add the Algolia Recommend widget to the desired
                            location.
                        </p>
                    </div>
                </FlexGrid>

                <FlexGrid
                    className={stl`w-full bg-grey-100 p-6`}
                    direction="column"
                    spacing="lg"
                >
                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Source *
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                description={
                                    <span>
                                        Source with models trained for Algolia
                                        Recommend
                                    </span>
                                }
                                state={{
                                    errors: [errors.selected_source],
                                    status: errors.selected_source
                                        ? 'invalid'
                                        : 'default',
                                }}
                            >
                                <SourceDropdown
                                    currentChannel={currentChannel}
                                    integration={currentIntegration}
                                    setSelectedSource={(
                                        current_source: string
                                    ): void => {
                                        setNewRecommend({
                                            ...(newRecommend as Recommend),
                                            config: {
                                                ...(newRecommend?.config as RecommendConfigType),
                                                current_source,
                                            },
                                        })
                                    }}
                                    selectedSource={
                                        newRecommend?.config?.current_source
                                    }
                                />
                            </Field>
                        </div>
                    </FlexGrid>
                    <FlexGrid
                        className={stl`flex justify-end w-full pr-25`}
                        spacing="sm"
                    >
                        <Button
                            variant="primary"
                            onClick={(): void => {
                                if (validate()) {
                                    handleSave()
                                }
                            }}
                            disabled={
                                (!isChanged && currentRecommend?.is_enabled) ||
                                isFromAdminView()
                            }
                            loading={isSaveLoading}
                        >
                            Save & Apply
                        </Button>
                        {shouldUpdateButtonShow() && (
                            <Button
                                variant="neutral"
                                onClick={(): void => {
                                    updateRecommendVersioning()
                                }}
                                disabled={isSaveLoading}
                                startIcon={ArrowUpCircle}
                            >
                                Update
                            </Button>
                        )}
                        {currentRecommend?.is_enabled && (
                            <Button
                                variant="destructive"
                                onClick={(): void => {
                                    setIsModalOpen(true)
                                }}
                                disabled={isFromAdminView()}
                            >
                                Remove
                            </Button>
                        )}
                    </FlexGrid>
                </FlexGrid>
            </Card>
        </>
    )
}
