import { CardHeader, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'

import type { Insight } from '../../../../types/database/insight.type'
import { useAuth } from '../../../AuthContext'

type Props = {
    currentInsights: Insight
    setCurrentInsights: (insights: Insight) => void
    isFromAdminView: () => boolean
}

export const UserTokenSettings: FunctionComponent<Props> = (props): any => {
    const { currentInsights, setCurrentInsights, isFromAdminView } = props
    const { platform } = useAuth()

    return (
        <>
            <CardHeader className={stl`px-4 mt-4 mb-2`}>
                <h2 className={stl`display-heading`}>UserToken Settings</h2>
            </CardHeader>

            <FlexGrid
                className={`stl-w-full stl-p-6`}
                direction="column"
                spacing="lg"
            >
                <label className={stl`cursor-pointer`}>
                    <input
                        disabled={!currentInsights?.is_enabled}
                        type="radio"
                        name="option"
                        checked={!currentInsights.config?.use_cookies}
                        onChange={(): void => {
                            setCurrentInsights({
                                ...(currentInsights as Insight),
                                config: {
                                    ...currentInsights?.config,
                                    use_cookies:
                                        !currentInsights.config?.use_cookies,
                                },
                            })
                        }}
                    />
                    <span style={{ marginLeft: '10px' }}>
                        userToken is set with a random alpha-numeric string at
                        every page refresh
                    </span>
                    <p className="stl-ml-10 stl-grey stl-p-2">
                        With this option, the userToken is renewed at every page
                        load. Events will count towards click and conversion
                        analytics, but not towards personalization.
                    </p>
                </label>
                <label className={stl`cursor-pointer`}>
                    <input
                        disabled={!currentInsights?.is_enabled}
                        type="radio"
                        name="option"
                        checked={currentInsights.config?.use_cookies}
                        onChange={(): void => {
                            setCurrentInsights({
                                ...(currentInsights as Insight),
                                config: {
                                    ...currentInsights?.config,
                                    use_cookies:
                                        !currentInsights.config?.use_cookies,
                                },
                            })
                        }}
                    />
                    <span style={{ marginLeft: '10px' }}>
                        userToken is set using cookies depending on the user's
                        approval
                    </span>
                    <p className="stl-ml-10 stl-grey stl-p-2">
                        This option only works if you're using
                        {!isFromAdminView() && (
                            <a
                                target={'_blank'}
                                href={`https://store-${platform.platform_id.replace(
                                    'stores/',
                                    ''
                                )}.mybigcommerce.com/manage/settings-list`}
                            >
                                {` BigCommerce's cookie consent tracking `}
                            </a>
                        )}
                        {isFromAdminView() &&
                            `BigCommerce's cookie consent tracking`}
                        to retrieve the user's consent to using cookies. If no
                        consent is given or the privacy API is not in use, a
                        random alpha-numeric string is used as userToken.
                    </p>
                </label>
            </FlexGrid>
        </>
    )
}
