import { getToken } from './tokenManager'
import { isEmpty } from './utils'

export type RequestHeader = {
    'Content-Type': string
    Accept: string
    Authorization?: string
}

export type Request = {
    method: string
    headers: RequestHeader
    body?: string
}

export const REQUEST_TYPES = {
    post: 'POST',
    get: 'GET',
    put: 'PUT',
    patch: 'PATCH',
    delete: 'DELETE',
}

export default async function makeRequest(
    url: string,
    method = 'GET',
    body: string | null = null
): Promise<any> {
    try {
        const headers: RequestHeader = {
            'Content-Type': 'application/json',
            Accept: 'application/vnd.api+json',
        }

        if (!isEmpty(getToken())) {
            headers.Authorization = `Bearer ${getToken()}`
        }

        const request: Request = {
            method,
            headers,
        }

        if (body !== null) {
            request.body = body
        }

        const response = await fetch(url, request)
        const data = await response.json()
        if (!response.ok) {
            if (data.error) {
                throw data.error
            }
        }

        return data
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
    }
}
