import { Button, FlexGrid, stl } from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { LogOut } from 'react-feather'
import { Link } from 'react-router-dom'

import algolia from '../../assets/images/algolia-small.svg'
import { clearToken } from '../../utils/tokenManager'
import { useAuth } from '../AuthContext'

import { Breadcrumbs } from './breadcrumbs'

export type HeaderSteps = {
    currentStep: number
    nbSteps: number
}

type HeaderProps = {
    title?: string
    steps?: HeaderSteps
}

export const Header: FunctionComponent<HeaderProps> = (
    props: HeaderProps
): any => {
    const { title, steps } = props
    const { platform } = useAuth()

    return (
        <div className="header-bar stl-w-full stl-p-5 stl-text-left stl-bg-white stl-flex stl-items-center">
            <FlexGrid spacing="sm" alignment="center">
                <Link to={`/`}>
                    <img
                        src={algolia}
                        className={stl`display-heading mr-4 w-6`}
                    />
                </Link>
                <Breadcrumbs />
                <h1 className="stl-display-heading  stl-text-grey-900">
                    {title}
                </h1>
            </FlexGrid>
            <div className={stl`flex items-center justify-between`}>
                {steps !== undefined && (
                    <h2 className="stl-display-heading stl-text-grey-900">
                        {`Step ${steps.currentStep} of ${steps.nbSteps}`}
                    </h2>
                )}
                {platform === null && (
                    <div className={'stl-px-2'}>
                        <Button
                            endIcon={LogOut}
                            variant="subtle"
                            onClick={(): void => {
                                clearToken()
                                location.href = `/api/v1/logout`
                            }}
                        >
                            Sign out
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}
