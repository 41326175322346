import {
    Button,
    Card,
    Field,
    FlexGrid,
    Modal,
    ModalSection,
    Pagination,
    stl,
    Table,
    Toggle,
    Input,
    IconButton,
    ModalFooter,
    TooltipWrapper,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useState, useEffect } from 'react'
import { Edit, HelpCircle, RefreshCw } from 'react-feather'

import BigCommerceService from '../../services/bigCommerce/bigCommerceService'
import type {
    BcCategoriesResponse,
    CategoryIds,
} from '../../types/bigCommerce/categories.type'
import type { Entity } from '../../types/database/entities.type'
import type {
    InstantSearch,
    InstantSearchFacet,
} from '../../types/database/instantSearch.type'
import { useAlert } from '../AlertContext'
import { useAuth } from '../AuthContext'
import { Loader } from '../common/loader'
import { RegionsDropdown } from '../common/regionsDropdown'
import { FacetList } from '../common/theming/facetList'

type CategoryItem = {
    id: number
    name: string
    isEnabled: boolean
    url?: string
}

type Props = {
    setCategoriesEntity: (categoriesEntity: Entity) => void
    categoriesEntity: Entity
    errors: any
    currentInstantSearch: InstantSearch | undefined
    facetableAttributes: Array<{ value: string; label: string }> | undefined
    setIsChanged: (isChanged: boolean) => void
    handleCategoryFacetUpdate: (selectedCategoryId: number) => void
    setUpdatedCategoryFacets: (updatedFacets: InstantSearchFacet[]) => void
    updatedCategoryFacets: InstantSearchFacet[]
    setIsCategoryModalOpen: (isOpen: boolean) => void
    isCategoryModalOpen: boolean
    isFromAdminView: () => boolean
    setCategoryFacetChange: (isChanged: boolean) => void
    isCategoryFacetChanged: boolean
    setPreviousCategoryToggleState: (state: boolean) => void
    hasMainToggleChanged: boolean
    setHasMainToggleChanged: (state: boolean) => void
    fetchIndexData: () => Promise<void>
}

export const Categories: FunctionComponent<Props> = (props) => {
    const {
        setCategoriesEntity,
        categoriesEntity,
        errors,
        currentInstantSearch,
        facetableAttributes,
        setIsChanged,
        handleCategoryFacetUpdate,
        setUpdatedCategoryFacets,
        updatedCategoryFacets,
        setIsCategoryModalOpen,
        isCategoryModalOpen,
        isFromAdminView,
        setCategoryFacetChange,
        isCategoryFacetChanged,
        setPreviousCategoryToggleState,
        hasMainToggleChanged,
        setHasMainToggleChanged,
        fetchIndexData,
    } = props

    const [displayedCategories, setDisplayedCategories] = useState<
        CategoryItem[]
    >([])
    const [categoriesLength, setCategoriesLength] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedCategory, setSelectedCategory] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [isHovered, setIsHovered] = useState(false)
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0)

    const { showErrorAlert } = useAlert()
    const { platform } = useAuth()

    const itemsPerPage = 5

    const updateCategoryIds = async (): Promise<CategoryIds> => {
        if (!categoriesEntity?.config) {
            return {}
        }
        const updatedCategoryIds = {
            ...categoriesEntity.config.category_ids,
        }

        const response: BcCategoriesResponse[] =
            await BigCommerceService.fetchCategories()

        // This map will hold the names and the times they appear
        // for fast lookup in duplicate checks below
        const nameCount = response.reduce(
            (acc: { [x: string]: any }, category: { name: string }) => {
                const name = category.name as string
                // eslint-disable-next-line no-param-reassign
                acc[name] = (acc[name] || 0) + 1
                return acc
            },
            {}
        )

        response.forEach((category: any) => {
            const categoryId = category.id as number
            const categoryName = category.name as string

            if (typeof category.custom_url.url === 'undefined') {
                return
            }

            const categoryURL = category.custom_url.url as string

            // Check if this category name is a duplicate
            // If it is a duplicate category name, append the URL to display
            const isDuplicate = nameCount[categoryName] > 1

            // Enable new categories by default
            if (!updatedCategoryIds[categoryId]) {
                updatedCategoryIds[categoryId] = {
                    name: categoryName,
                    isEnabled: true,
                    facets: [],
                    ...(isDuplicate && { url: categoryURL }),
                }
            } else {
                updatedCategoryIds[categoryId] = {
                    ...updatedCategoryIds[categoryId],
                    name: categoryName,
                    ...(isDuplicate && { url: categoryURL }),
                }
            }
        })

        return updatedCategoryIds
    }

    useEffect(() => {
        if (!categoriesEntity?.config) {
            return
        }

        const startIndex = (currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage

        const categoriesObject = categoriesEntity.config.category_ids

        // In order to support pagination we must convert the map to an array with id, name, status
        const categoriesWithIds = Object.entries(categoriesObject).map(
            ([id, details]: any) => {
                return { id: parseInt(id, 10), ...details }
            }
        )

        const slicedCategories = categoriesWithIds.slice(startIndex, endIndex)

        setCategoriesLength(categoriesWithIds.length)
        setDisplayedCategories(slicedCategories)
    }, [categoriesEntity, currentPage])

    const handleRefresh = async (): Promise<void> => {
        try {
            setLoading(true)

            const updatedCategoryEntity = { ...categoriesEntity }

            if (!updatedCategoryEntity?.config) {
                return
            }

            const refreshCategoryIds = await updateCategoryIds()

            if (!refreshCategoryIds) {
                return
            }

            updatedCategoryEntity.config.category_ids = refreshCategoryIds

            setCategoriesEntity(updatedCategoryEntity)
        } catch (error) {
            showErrorAlert('Unable to refresh categories')
        } finally {
            setLoading(false)
        }
    }

    const handleMainCategoryToggle = async (
        event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        try {
            const newEntity = { ...categoriesEntity }
            setLoading(true)
            if (!newEntity?.config) {
                return
            }

            setPreviousCategoryToggleState(newEntity.config.isEnabled)
            setHasMainToggleChanged(true)

            newEntity.config.isEnabled = event.target.checked
            const refreshCategoryIds = await updateCategoryIds()

            if (!refreshCategoryIds) {
                setCategoriesEntity(newEntity)
                return
            }

            newEntity.config.category_ids = refreshCategoryIds
            setCategoriesEntity(newEntity)
        } catch (error) {
            showErrorAlert('Unable to toggle categories')
        } finally {
            setLoading(false)
        }
    }

    const handleCategoryToggle = (categoryId: number): void => {
        try {
            const updatedCategoryEntity = { ...categoriesEntity }

            if (!updatedCategoryEntity?.config) {
                return
            }

            updatedCategoryEntity.config.category_ids[categoryId] = {
                ...updatedCategoryEntity.config?.category_ids[categoryId],
                isEnabled:
                    !updatedCategoryEntity.config?.category_ids[categoryId]
                        .isEnabled,
            }

            setCategoriesEntity(updatedCategoryEntity)
        } catch (error) {
            showErrorAlert('Unable to toggle category', categoryId)
        }
    }

    const handleCategoryRegionUpdate = (placement_region: string): void => {
        try {
            const updatedCategoryEntity = { ...categoriesEntity }

            if (!updatedCategoryEntity?.config) {
                return
            }

            updatedCategoryEntity.config.placement_region = placement_region

            setCategoriesEntity(updatedCategoryEntity)
        } catch (error) {
            showErrorAlert(
                'Unable to update category placement region',
                placement_region
            )
        }
    }

    const handleCategorySelectorUpdate = (
        category_css_selector: string
    ): void => {
        try {
            const updatedCategoryEntity = { ...categoriesEntity }

            if (!updatedCategoryEntity.config) {
                return
            }

            updatedCategoryEntity.config.css_selector = category_css_selector

            setCategoriesEntity(updatedCategoryEntity)
        } catch (error) {
            showErrorAlert(
                'Unable to update category search CSS selector',
                category_css_selector
            )
        }
    }

    function getTooltipContent(): string {
        if (
            currentInstantSearch === undefined ||
            currentInstantSearch.id === undefined
        ) {
            return 'You must configure InstantSearch before editing category facets.'
        }
        if (typeof categoriesEntity.id === 'undefined') {
            return 'You must save InstantSearch before editing category facets for the first time.'
        }
        if (hasMainToggleChanged) {
            return 'You must save InstantSearch before editing category facets.'
        }
        return ''
    }

    function shouldCategoriesBeDisabled(): boolean {
        if (
            typeof currentInstantSearch?.config?.javascript_version ===
                'undefined' &&
            typeof currentInstantSearch?.config?.css_version === 'undefined'
        ) {
            return true
        }

        if (
            currentInstantSearch?.config?.javascript_version &&
            currentInstantSearch?.config?.css_version &&
            (currentInstantSearch?.config?.javascript_version < '1.12' ||
                currentInstantSearch?.config?.css_version < '1.12') &&
            typeof platform !== 'undefined'
        ) {
            return true
        }
        return false
    }

    function shouldFacetsBeDisabled(): boolean {
        return (
            hasMainToggleChanged ||
            categoriesEntity?.config?.isEnabled === false ||
            currentInstantSearch === undefined ||
            currentInstantSearch.id === undefined ||
            typeof categoriesEntity.id === 'undefined'
        )
    }

    return (
        <>
            <Modal
                open={isCategoryModalOpen}
                title={`${selectedCategory} (ID ${selectedCategoryId}) - Category Facets`}
                animate={true}
                onDismiss={(): void => {
                    setIsCategoryModalOpen(false)
                }}
                centerY={false}
                fullBleed={true}
                size="medium"
                autoFocusOnOpenElement={false}
            >
                <ModalSection>
                    <FlexGrid
                        direction="row"
                        alignment="center"
                        spacing="md"
                        className="stl-ml-23 stl-source-header stl-pb-10"
                    >
                        <FacetList
                            facetableAttributes={
                                facetableAttributes?.filter((item) => {
                                    const facets =
                                        categoriesEntity?.config?.category_ids[
                                            selectedCategoryId
                                        ]?.facets?.concat(updatedCategoryFacets)

                                    if (!facets || facets.length === 0) {
                                        return true
                                    }

                                    return !facets
                                        .map(
                                            (usedItem): string =>
                                                usedItem.attribute
                                        )
                                        .includes(item.value)
                                }) ?? []
                            }
                            isFromAdminView={isFromAdminView}
                            setIsChanged={setCategoryFacetChange}
                            facets={
                                categoriesEntity?.config?.category_ids[
                                    selectedCategoryId
                                ]?.facets ?? []
                            }
                            onFacetChange={(
                                updatedFacets: InstantSearchFacet[]
                            ): void => {
                                setUpdatedCategoryFacets(updatedFacets)
                            }}
                            fetchIndexData={fetchIndexData}
                        />
                    </FlexGrid>
                    <FlexGrid
                        direction="row"
                        alignment="center"
                        spacing="md"
                        className="stl-ml-23 stl-pl-23"
                    >
                        <p className="stl-muted-text">
                            If a category does not have any facets it will
                            inherit the default InstantSearch facets.
                        </p>
                    </FlexGrid>
                </ModalSection>
                <ModalFooter>
                    <FlexGrid className={stl`w-full mb-4`} distribution="fill">
                        <FlexGrid className={stl`pl-23`} distribution="leading">
                            <Button
                                variant="primary"
                                disabled={
                                    isFromAdminView() || !isCategoryFacetChanged
                                }
                                onClick={(): void => {
                                    handleCategoryFacetUpdate(
                                        selectedCategoryId
                                    )
                                }}
                                loading={loading}
                            >
                                Save
                            </Button>
                        </FlexGrid>
                    </FlexGrid>
                </ModalFooter>
            </Modal>
            <FlexGrid className={stl`w-full`}>
                <h2
                    className={stl`display-subheading`}
                    style={{ width: '250px' }}
                >
                    Categories
                </h2>
                <Card fullBleed style={{ width: 600 }}>
                    <Card.Header
                        className={stl`p-6 flex items-center border-b border-grey-200`}
                    >
                        <div>
                            <Card.Title>InstantSearch Categories</Card.Title>
                            <p className={stl`display-caption`}>
                                This will enable or disable InstantSearch for
                                all categories. Or you can toggle InstantSearch
                                for individual categories below. InstantSearch
                                must be enabled in addition to IS Categories.
                                {shouldCategoriesBeDisabled() &&
                                    typeof currentInstantSearch?.id !==
                                        'undefined' && (
                                        <b>
                                            {' '}
                                            Please upgrade InstantSearch
                                            JavaScript & CSS to v1.12 or greater
                                            to enable InstantSearch categories.
                                        </b>
                                    )}
                                {typeof currentInstantSearch?.id ===
                                    'undefined' && (
                                    <b>
                                        {' '}
                                        You must save InstantSearch before
                                        enabling InstantSearch categories.
                                    </b>
                                )}
                            </p>
                        </div>
                        <div style={{ paddingRight: '0.5rem' }}>
                            <Toggle
                                defaultChecked={false}
                                checked={
                                    categoriesEntity?.config?.isEnabled ?? false
                                }
                                disabled={
                                    shouldCategoriesBeDisabled() ||
                                    isFromAdminView()
                                }
                                onChange={(e): void => {
                                    handleMainCategoryToggle(e)
                                }}
                            ></Toggle>
                        </div>
                        <div>
                            <IconButton
                                icon={RefreshCw}
                                title="Fetch the latest categories from BigCommerce"
                                variant="subtle"
                                disabled={
                                    (!categoriesEntity?.config?.isEnabled ||
                                        isFromAdminView()) ??
                                    true
                                }
                                onClick={(): void => {
                                    handleRefresh()
                                }}
                                onMouseEnter={(): void => setIsHovered(true)}
                                onMouseLeave={(): void => setIsHovered(false)}
                                showTooltip={isHovered}
                            />
                        </div>
                    </Card.Header>
                    {loading && <Loader />}
                    {!loading && (
                        <Table
                            footer={`Showing ${displayedCategories.length} of ${categoriesLength} categories`}
                        >
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th
                                        className="text-right"
                                        style={{ paddingRight: '2.5rem' }}
                                    >
                                        Facets
                                    </th>
                                    <th className="text-right">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedCategories.map((category) => (
                                    <tr key={category.id}>
                                        <td className="text-left">
                                            {category.url ? (
                                                <TooltipWrapper
                                                    delay={250}
                                                    content={`Duplicate category name. Path for category: ${category.url}`}
                                                    align={'start'}
                                                    alignOffset={80}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        {category.name}
                                                        <HelpCircle
                                                            size={'14px'}
                                                            color="blue"
                                                            className={stl`ml-5`}
                                                        ></HelpCircle>
                                                    </div>
                                                </TooltipWrapper>
                                            ) : (
                                                category.name
                                            )}
                                        </td>
                                        <td className="text-right">
                                            <TooltipWrapper
                                                delay={250}
                                                content={getTooltipContent()}
                                            >
                                                <Button
                                                    onClick={async (): Promise<void> => {
                                                        await fetchIndexData()
                                                        setSelectedCategory(
                                                            category.name
                                                        )
                                                        setIsCategoryModalOpen(
                                                            true
                                                        )
                                                        setSelectedCategoryId(
                                                            category.id
                                                        )
                                                    }}
                                                    startIcon={Edit}
                                                    className="mb-10"
                                                    size="medium"
                                                    variant="neutral"
                                                    disabled={shouldFacetsBeDisabled()}
                                                >
                                                    Edit
                                                </Button>
                                            </TooltipWrapper>
                                        </td>
                                        <td className="text-right">
                                            <div className="flex justify-end">
                                                <Toggle
                                                    checked={category.isEnabled}
                                                    disabled={
                                                        (!categoriesEntity
                                                            ?.config
                                                            ?.isEnabled ??
                                                            true) ||
                                                        isFromAdminView()
                                                    }
                                                    onChange={(): void => {
                                                        setIsChanged(true)
                                                        handleCategoryToggle(
                                                            category.id
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                    {!loading &&
                        Math.ceil(categoriesLength / itemsPerPage) > 1 && (
                            <>
                                <div
                                    className={stl`flex justify-center bg-grey-100 pb-2`}
                                >
                                    <Pagination
                                        onChange={(newPage: number): void => {
                                            setCurrentPage(newPage)
                                        }}
                                        nbPages={Math.ceil(
                                            categoriesLength / itemsPerPage
                                        )}
                                        currentPage={currentPage}
                                        maxButtons={5}
                                    />
                                </div>
                            </>
                        )}
                </Card>
            </FlexGrid>
            <FlexGrid className={stl`w-full`}>
                <h2
                    className={stl`display-subheading`}
                    style={{ width: '250px' }}
                >
                    Categories Placement Region
                </h2>
                <Field
                    description={
                        <span>
                            Placement region to place InstantSearch Categories
                            for your theme. <br></br>
                            Required when InstantSearch Categories are enabled.
                        </span>
                    }
                    state={{
                        errors: [errors.category_region],
                        status: errors.category_region ? 'invalid' : 'default',
                    }}
                >
                    <RegionsDropdown
                        templateFile="pages/category"
                        setSelectedPlacementRegion={handleCategoryRegionUpdate}
                        selectedPlacementRegion={
                            categoriesEntity?.config?.placement_region ??
                            'category_below_header'
                        }
                        disabled={
                            shouldCategoriesBeDisabled() ||
                            categoriesEntity?.config?.isEnabled === false
                        }
                    ></RegionsDropdown>
                </Field>
            </FlexGrid>
            <FlexGrid className={stl`w-full`}>
                <h2
                    className={stl`display-subheading`}
                    style={{
                        width: '250px',
                    }}
                >
                    Categories CSS Selector
                </h2>
                <div className={stl`w-400 flex justify-start`}>
                    <Field
                        className={stl`w-full`}
                        description={
                            <span>
                                CSS Selector for hiding the default page content
                                search. You might have to change this value to
                                an element selector that works for your theme.
                                This field is case sensitive and required when
                                InstantSearch Categories is enabled.
                            </span>
                        }
                        state={{
                            errors: [errors.category_css_selector],
                            status: errors.category_css_selector
                                ? 'invalid'
                                : 'default',
                        }}
                    >
                        <Input
                            variant="small"
                            placeholder="page"
                            value={categoriesEntity?.config?.css_selector}
                            onChange={(event: any): void =>
                                handleCategorySelectorUpdate(event.target.value)
                            }
                            disabled={
                                shouldCategoriesBeDisabled() ||
                                categoriesEntity?.config?.isEnabled === false
                            }
                        />
                    </Field>
                </div>
            </FlexGrid>
        </>
    )
}
