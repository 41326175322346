import type { BcCategoriesResponse } from '../../types/bigCommerce/categories.type'
import type {
    CategoriesMetafield,
    CategoriesMetafieldData,
    CategoriesMetafieldResponse,
} from '../../types/bigCommerce/categoriesMetafield.type'
import type { BcChannelsResponse } from '../../types/bigCommerce/channelsRespone.type'
import type { BcCurrenciesResponse } from '../../types/bigCommerce/currenciesRespone.type'
import type { BcRegionsResponse } from '../../types/bigCommerce/regionsResponse.type'
import type { BcStoreInfoResponse } from '../../types/bigCommerce/storeInfoResponse.type'
import makeRequest, { REQUEST_TYPES } from '../../utils/request'

const BigCommerceService = {
    async fetchChannels(): Promise<BcChannelsResponse> {
        return await makeRequest(
            `/api/v1/bigcommerce/channels`,
            REQUEST_TYPES.get
        )
    },
    async fetchStoreInfo(): Promise<BcStoreInfoResponse> {
        return await makeRequest(
            `/api/v1/bigcommerce/store-info`,
            REQUEST_TYPES.get
        )
    },
    async fetchCurrencies(): Promise<BcCurrenciesResponse> {
        return await makeRequest(
            `/api/v1/bigcommerce/currencies`,
            REQUEST_TYPES.get
        )
    },
    async fetchRegions(
        templateFile: string = 'pages/home'
    ): Promise<BcRegionsResponse> {
        return await makeRequest(
            `/api/v1/bigcommerce/regions?template_file=${templateFile}`,
            REQUEST_TYPES.get
        )
    },
    async fetchDataLayerStatus(): Promise<BcRegionsResponse> {
        return await makeRequest(
            `/api/v1/bigcommerce/data-layer-status`,
            REQUEST_TYPES.get
        )
    },
    async fetchCategories(): Promise<BcCategoriesResponse[]> {
        return await makeRequest(
            `/api/v1/bigcommerce/categories`,
            REQUEST_TYPES.get
        )
    },
    async createCategoryMetafield(
        categoryId: number,
        data: CategoriesMetafieldData
    ): Promise<CategoriesMetafield> {
        return await makeRequest(
            `/api/v1/bigcommerce/categories/${categoryId}/metafields`,
            REQUEST_TYPES.post,
            JSON.stringify(data)
        )
    },
    async updateCategoryMetafield(
        categoryId: number,
        metafieldId: number,
        data: CategoriesMetafieldData
    ): Promise<CategoriesMetafieldResponse> {
        return await makeRequest(
            `/api/v1/bigcommerce/categories/${categoryId}/metafields/${metafieldId}`,
            REQUEST_TYPES.put,
            JSON.stringify(data)
        )
    },
    async deleteCategoryMetafield(
        categoryId: number,
        metafieldId: number
    ): Promise<Response> {
        return await makeRequest(
            `/api/v1/bigcommerce/categories/${categoryId}/metafields/${metafieldId}`,
            REQUEST_TYPES.delete
        )
    },
}

export default BigCommerceService
